import { Loadable } from '@dbel/react-commons/components';
import { lazy } from 'react';
import { ModuleNotFound } from '../components/ModuleNotFound';

// Module federation Remotes
export const HVAC = Loadable(
  lazy(() =>
    import('hvac/HVAC')
      .then((module) => ({
        default: module.default.HVAC,
      }))
      .catch(() => ({
        default: ModuleNotFound,
      }))
  )
);
export const Architect = Loadable(
  lazy(() =>
    import('architect/Architect')
      .then((module) => ({
        default: module.default.Architect,
      }))
      .catch(() => ({
        default: ModuleNotFound,
      }))
  )
);

export const RoomAcousticCalculator = Loadable(
  lazy(() =>
    import('architect/RoomAcousticCalculator')
      .then((module) => ({
        default: module.default.RoomAcousticCalculator,
      }))
      .catch(() => ({
        default: ModuleNotFound,
      }))
  )
);

export const DBelOrganisations = Loadable(
  lazy(() =>
    import('dBelOrganisations/App')
      .then((module) => ({
        default: module.default.App,
      }))
      .catch(() => ({
        default: ModuleNotFound,
      }))
  )
);

export const DBelInventory = Loadable(
  lazy(() =>
    import('dBelInventory/App')
      .then((module) => ({
        default: module.default.App,
      }))
      .catch(() => ({
        default: ModuleNotFound,
      }))
  )
);
