import { getDBelApiCredentials, useGetProductsQuery } from '@dbel/react-commons/api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import lightTheme from '../theme/lightTheme';
import { showCaseProjectIDs } from '@dbel/react-commons/types';
import useAuth from '../hooks/useAuth';
import i18n from 'i18next';
import { Architect, RoomAcousticCalculator, HVAC } from './loadables';
import { availableApps } from '../AppRegister';

export const AppRoutes = () => {
  const location = useLocation();
  const { getTokenSilently } = useAuth();
  const [telemetryToken, setTelemetryToken] = useState<string>();

  // TODO: at the moment Architect as remote app does not have an auth0 lib, we cannot use getTokenSilently
  // this is a temporary workaround, we should use a shared context for this purposes. See module federation react-shared-context
  const isArchitectRoute = location.pathname.includes('app/architect/');
  const projectId = location.pathname.substring(15);
  const isProjectShowCase = showCaseProjectIDs.includes(projectId);

  useEffect(() => {
    if (!isArchitectRoute || !isProjectShowCase) return;

    const asyncGetToken = async () => {
      const asynctoken = await getTokenSilently({
        cacheMode: 'off',
        authorizationParams: {
          audience: 'https://telemetry.test.dbel.cloud',
        },
      });
      if (asynctoken) {
        setTelemetryToken(asynctoken);
      }
    };
    asyncGetToken();
  }, [getTokenSilently, isArchitectRoute, isProjectShowCase, location]);
  const { data: products } = useGetProductsQuery();

  const apps = useMemo(() => {
    if (!products) return;

    return availableApps(products);
  }, [products]);

  const getApp = useCallback(
    (appName: string) => {
      if (isProjectShowCase && !telemetryToken) return <></>;
      switch (appName) {
        case 'architect': {
          return (
            <Architect
              apiCredentials={getDBelApiCredentials()}
              language={i18n.language}
              telemetryToken={telemetryToken}
            />
          );
        }
        case 'roomacousticcalculator':
          return <RoomAcousticCalculator apiCredentials={getDBelApiCredentials()} language={i18n.language} />;
        case 'hvac':
          return (
            <HVAC
              apiCredentials={getDBelApiCredentials()}
              language={i18n.language}
              muiTheme={lightTheme}
              environment="PLATFORM"
            />
          );
      }
    },
    [isProjectShowCase, telemetryToken]
  );

  return (
    <Routes>
      {apps?.map((app) => (
        <Route path={app.path + '/*'} element={getApp(app.name)} key={app.name} />
      ))}
      ;
    </Routes>
  );
};
