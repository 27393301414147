import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Drawer, IconButton, Stack, Theme, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { MapBoxOverlayContainer } from '@dbel/react-commons/components';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AccountPopover from '../components/dashboard/AccountPopover';
import { SideMenu } from './SideMenu';
import useAuth from '../hooks/useAuth';
import { Link, useLocation, useParams } from 'react-router-dom';
import { DBelLogoSvg } from '@dbel/react-commons/assets';
import { BackendConnectionContextProvider } from '../contexts/BackendConnectionContext';
import { LoadingIndicator } from '../components/loadingIndicator/LoadingIndicator';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { useGetProjectByIdQuery } from '@dbel/react-commons/api';
import { AppRegister } from '../AppRegister';
import { ProjectType } from '@dbel/shared/types';
import i18n from 'i18next';

const getIcon = (projectType: ProjectType) => {
  if (AppRegister[projectType]) {
    return AppRegister[projectType].icon;
  }
  return <>{projectType}</>;
};

const getApplicationName = (projectType: ProjectType) => {
  return i18n.t(`pages.menus.applications.${projectType}.title`);
};

export const TopMenu = () => {
  const auth = useAuth();
  const [appMenuOpen, setAppMenuOpen] = useState(false);

  const handleAppMenuToggle = () => {
    setAppMenuOpen(!appMenuOpen);
  };

  const location = useLocation();

  useEffect(() => {
    setAppMenuOpen(false);
  }, [location]);

  const isAppRoute = location.pathname.includes('app');
  // workaround for disabling settings for other apps
  const isArchitectRoute = location.pathname.includes('architect');
  // because we have subroutings with id we use this workaround

  const path = useParams()['*'];
  const subPath = path?.substring(path.indexOf('project/') + 8, path.indexOf('project/') + 44);
  const projectId = subPath?.length === 36 ? subPath : undefined;

  const { data: project, isSuccess: hasProjectData } = useGetProjectByIdQuery(projectId ?? skipToken);

  return (
    <>
      {auth.accountStatus === 'ACTIVE' && (
        <>
          <MapBoxOverlayContainer
            sx={{
              display: 'flex',
              height: '48px',
              borderRadius: 0,
              alignItems: 'center',
            }}
          >
            <IconButton onClick={handleAppMenuToggle} sx={{ ml: 2 }}>
              <MenuIcon />
            </IconButton>
            <DBelLogoSvg sx={{ fontSize: 40, ml: 0, mr: 2 }} />

            {isAppRoute && hasProjectData && (
              <>
                <Box
                  sx={{
                    height: 12,
                    width: 12,
                    mr: 1,
                    mt: '-12px',
                  }}
                >
                  {getIcon(project.type)}
                </Box>
                <Typography variant="body2" sx={{ color: 'e6e6e6' }}>
                  {getApplicationName(project.type)}
                  {'/ '}
                </Typography>
                <Typography variant="body2" noWrap>
                  {project.name}
                </Typography>

                {isArchitectRoute && (
                  <IconButton component={Link} to={`${location.pathname}/settings`}>
                    <SettingsOutlinedIcon />
                  </IconButton>
                )}
              </>
            )}

            <Stack
              direction="row"
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
              }}
            >
              {hasProjectData && isAppRoute && (
                <BackendConnectionContextProvider projectId={projectId}>
                  <LoadingIndicator projectId={projectId} />
                </BackendConnectionContextProvider>
              )}
              <AccountPopover
                sx={{
                  width: '48px',
                  height: '48px',
                  pointerEvents: 'auto',
                }}
              />
            </Stack>
          </MapBoxOverlayContainer>
          <Drawer
            variant="temporary"
            open={appMenuOpen}
            onClose={handleAppMenuToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              '& .MuiDrawer-paper': { borderRadius: 0, width: 300 },
              zIndex: (theme: Theme) => theme.zIndex.modal + 1,
            }}
          >
            <IconButton sx={{ position: 'absolute', right: 5, top: 12 }} onClick={handleAppMenuToggle}>
              <CloseIcon />
            </IconButton>
            <SideMenu />
          </Drawer>
        </>
      )}
    </>
  );
};
