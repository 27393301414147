import { Route, Routes, useParams } from 'react-router-dom';
import { HVAC } from './loadables';
import { getDBelApiCredentials, useGetConfigForApplicationKeyQuery } from '@dbel/react-commons/api';
import i18n from 'i18next';
import { AllWidgetsLayout } from '../pages/AllWidgetsLayout';
import { skipToken } from '@reduxjs/toolkit/dist/query';

const authorizationType = 'KEY';
// TODO: at the moment we only have one key for stiebel
const apiKey = import.meta.env.VITE_DBEL_API_KEY;

export const WidgetRoutes = () => {
  const apiCredential = getDBelApiCredentials();
  const { applicationKey } = useParams();
  const { data: appconfig } = useGetConfigForApplicationKeyQuery(applicationKey ?? skipToken);
  return (
    <Routes>
      <Route element={<AllWidgetsLayout />}>
        {appconfig && appconfig.product.key === 'HVAC' && (
          <Route
            path="*"
            element={
              <HVAC
                apiCredentials={{
                  wsEndpoint: apiCredential.wsEndpoint,
                  apiEndpoint: apiCredential.apiEndpoint,
                  accountKey: appconfig.account.key,
                  authorizationType,
                  apiKey,
                }}
                language={i18n.language}
                applicationKey={applicationKey}
              />
            }
          />
        )}
      </Route>
      <Route path="*" element={<></>} />
    </Routes>
  );
};
