import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/Auth0Context.tsx';
import { Provider } from 'react-redux';
import store from './store.ts';
import { HelmetProvider } from 'react-helmet-async';
import lightTheme from './theme/lightTheme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { setDBelEndpoints } from '@dbel/react-commons/api';
import { apiConfig, websocketConfig } from './config.ts';

setDBelEndpoints({
  wsEndpoint: websocketConfig.endpointBaseUrl,
  apiEndpoint: apiConfig.endpointBaseUrl,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <HelmetProvider>
    <Provider store={store}>
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        <AuthProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    </Provider>
  </HelmetProvider>
);
