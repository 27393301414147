import { styled, ThemeProvider } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import lightTheme from '../theme/lightTheme';
import { CssBaseline } from '@mui/material';
import { useConsent } from '../hooks/useConsent';
const MainContainer = styled('div')(({ theme }) =>
  theme.unstable_sx({
    height: '100vh',
  })
);

export const AllPagesLayout = () => {
  useConsent();
  return (
    <>
      <MainContainer>
        <ThemeProvider theme={lightTheme}>
          <CssBaseline />
          <Outlet />
        </ThemeProvider>
      </MainContainer>
    </>
  );
};
